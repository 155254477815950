import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { ComponentClass, PureComponent } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndClose,
  answerMultipleQuestionsAndSubmit,
} from '^/actions/actionSequences';
import ThreeSixtyQuestion from '^/components/dashboard/enduser/answer/ThreeSixtyQuestion';
import LiveButton from '^/components/LiveButton';
import Well from '^/components/Well';
import {
  getLikertBoundsInformation,
  LikertBounds,
  PaginationInformation360,
  QUESTIONS_PER_PAGE,
} from '^/productVersions';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import { interpolate } from '^/utils';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import { multiProductVersionQuestionProgress } from './QuestionProgress';
import ScrollTopOnPageChange from './ScrollTopOnPageChange';

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  raterFor: Map<string, any>;
  questionCollectionIdx: number;
  questionsWithAnswers: List<List<Map<string, any>>>;
}

interface StateProps {
  user: Map<string, any>;
  progress: Map<string, any>;
  isPending: boolean;
  response: Map<string, any>;
  uiLanguage: string;
}

interface DispatchProps {
  setProductVersionPage: typeof setProductVersionPage;
  storeProductVersionAnswer: typeof storeProductVersionAnswer;
  push: typeof push;
  answerMultipleQuestionsAndAdvance: typeof answerMultipleQuestionsAndAdvance;
  answerMultipleQuestionsAndSubmit: typeof answerMultipleQuestionsAndSubmit;
  answerMultipleQuestionsAndClose: typeof answerMultipleQuestionsAndClose;
}

type Props = StateProps & DispatchProps & OwnProps;

export class ThreeSixtyProductVersion extends PureComponent<Props> {
  private paginationInformation: PaginationInformation360;

  constructor(props: Props) {
    super(props);
    this.paginationInformation = new PaginationInformation360(
      props.questionsWithAnswers,
      props.progress
    );
  }

  public componentDidMount() {
    this.props.setProductVersionPage(
      this.paginationInformation.firstUnansweredPageIndex
    );
  }

  public render() {
    const {
      isPending,
      questionsWithAnswers,
      progress,
      response,
      raterFor,
      productVersion,
      questionCollectionIdx,
    } = this.props;

    this.paginationInformation.progress = progress;
    this.paginationInformation.questionsWithAnswers = questionsWithAnswers;

    const currentPageIndex = this.paginationInformation.currentPageIndex;
    const pageCount = this.paginationInformation.pageCount;
    const pageIsComplete = this.isPageComplete(
      this.paginationInformation.currentPage
    );
    const isViewingLastPage = this.paginationInformation.isViewingLastPage;
    const action = isViewingLastPage
      ? this.props.answerMultipleQuestionsAndSubmit
      : this.props.answerMultipleQuestionsAndAdvance;

    const canResume = progress.get('canResume');

    const errors =
      response.get('state', false) === 'FAILED'
        ? response.get('errors')
        : List();

    const bounds = getLikertBoundsInformation(productVersion);

    const isStartOfTextQuestions =
      currentPageIndex === this.paginationInformation.startOfTextQuestions;

    const freeTextQuestionsCount = this.props.questionsWithAnswers
      .filter(questionAndAnswer =>
        questionAndAnswer.get(0).getIn(['question360', 'is_freetext'])
      )
      .count();

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPageIndex}
        />
        <AssessmentBody
          className="mb-none"
          header={multiProductVersionQuestionProgress(
            this.props.progress.get('currentPage', 0),
            {
              listSize:
                this.props.questionsWithAnswers.count() -
                freeTextQuestionsCount,
              pageSize: QUESTIONS_PER_PAGE,
            },
            {
              listSize: freeTextQuestionsCount,
              pageSize: freeTextQuestionsCount,
            }
          )}
          questionIndex={currentPageIndex}
        >
          <ScrollTopOnPageChange page={currentPageIndex} />
          <Well>{this.getIntroText(isStartOfTextQuestions)}</Well>
          <div>
            {this.paginationInformation.currentPage
              .map((questionAndAnswer, index: number) => {
                const question = questionAndAnswer.get(0);
                const existingAnswer = questionAndAnswer.get(1);

                const questionErrors = errors.get(index, Map());
                const error = Map.isMap(questionErrors)
                  ? questionErrors.get('text', null)
                  : null;
                const answer = this.getSelectedAnswer(
                  question,
                  existingAnswer,
                  bounds
                );
                return (
                  <ThreeSixtyQuestion
                    question={question}
                    error={error}
                    answer={answer}
                    key={question.get('id')}
                    onAnswer={this.onAnswer.bind(this, question)}
                    isSelfRating={!raterFor}
                    orgName={this.props.activity.getIn([
                      'organisation',
                      'name',
                    ])}
                    bounds={bounds}
                    isPending={isPending}
                  />
                );
              })
              .toArray()}
          </div>
          {isViewingLastPage && <Well>{this.get360EndText()}</Well>}
        </AssessmentBody>
        <AssessmentFooter
          isNotComplete={!pageIsComplete}
          isSaving={isPending}
          showContinue={canResume}
          onContinueLater={this.confirmAndClose}
          onContinue={this.onNext.bind(this, action)}
          isEnd={isViewingLastPage}
        >
          {!this.paginationInformation.isViewingFirstPage && (
            <LiveButton
              notPrimary
              disabled={isPending}
              onClick={this.onBack}
              buttonText={i18next.t<string>('Back')}
            />
          )}
        </AssessmentFooter>
      </AssessmentContainer>
    );
  }

  private getIntroText(isStartOfTextQuestions: boolean) {
    const { productVersion, raterFor } = this.props;
    if (productVersion.get('on_page_guide_text')) {
      return productVersion.get('on_page_guide_text');
    }

    if (isStartOfTextQuestions) {
      const template = productVersion.getIn([
        'productversion360',
        raterFor
          ? 'written_comments_intro_rater'
          : 'written_comments_intro_self',
      ]);
      return this.interpolateProductString(template);
    }

    if (raterFor) {
      return i18next.t<string>(
        'Please indicate your rating for {{raterFullName}} for each of the behaviours below',
        { raterFullName: raterFor.get('full_name') }
      );
    }
    return i18next.t<string>(
      'Please indicate your level of agreement for each of the statements below'
    );
  }

  private onAnswer(question: Map<string, any>, value: string | number) {
    this.props.storeProductVersionAnswer(question.get('id'), value);
  }

  private getSelectedAnswer(
    question: Map<string, any>,
    existingAnswer: Map<string, any>,
    bounds: LikertBounds
  ) {
    const answer = this.props.progress.getIn(
      ['unsavedResponses', question.get('id')],
      null
    );
    if (answer !== null) {
      return answer;
    }
    if (existingAnswer) {
      const score = existingAnswer.get('score', null);
      if (score !== null && question.get('reversed')) {
        return bounds.likert_maximum - existingAnswer.get('score') + 1;
      }
      return score !== null ? score : existingAnswer.get('text');
    }
    return null;
  }

  private onBack = () => {
    this.changePage(this.props.answerMultipleQuestionsAndAdvance, -1);
  };

  private onNext(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsAndSubmit
  ) {
    this.changePage(actionCreator, 1);
  }

  private changePage(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsAndSubmit,
    pageOffset: number
  ) {
    const destinationPage =
      this.props.progress.get('currentPage', 0) + pageOffset;
    const answers = this.props.progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number, question: Map<string, any>) => {
        return Map({
          question,
          [typeof score === 'string' ? 'text' : 'score']: score,
        });
      })
      .toList();

    actionCreator(
      this.props.activity,
      this.props.productVersion,
      this.props.raterFor,
      answers,
      destinationPage,
      1,
      this.props.uiLanguage
    );
  }

  private confirmAndClose = () => {
    if (
      window.confirm(
        i18next.t<string>(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      this.onNext(this.props.answerMultipleQuestionsAndClose);
    }
  };

  private isPageComplete(currentPage: List<List<Map<string, any>>>) {
    return currentPage.every(questionAndAnswer => {
      const question = questionAndAnswer.get(0);
      const answer = questionAndAnswer.get(1);

      const unsavedResponse = this.props.progress.getIn([
        'unsavedResponses',
        question.get('id'),
      ]);
      if (question.getIn(['question360', 'is_freetext'])) {
        return true;
      }
      return unsavedResponse !== undefined || answer;
    });
  }

  private interpolateProductString(str?: string) {
    const { raterFor, productVersion, user } = this.props;
    return interpolate(str || '', {
      PRODUCT_VERSION_NAME: productVersion.getIn(['product', 'name']),
      RESPONDENT_NAME: (raterFor ? raterFor : user).get('full_name'),
    })
      .split('\n')
      .map((line: string, index: number) => <p key={index}>{line}</p>);
  }

  private get360EndText() {
    const { productVersion, raterFor } = this.props;
    const template = productVersion.getIn([
      'productversion360',
      raterFor ? 'completed_text_rater' : 'completed_text_self',
    ]);

    return this.interpolateProductString(template);
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('loadItem'),
      state.responses.get('getCollection'),
      state.responses.get('answerQuestion'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    response: state.responses.get('answerQuestion'),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(
  mapStateToProps,
  {
    setProductVersionPage,
    storeProductVersionAnswer,
    push,
    answerMultipleQuestionsAndAdvance,
    answerMultipleQuestionsAndSubmit,
    answerMultipleQuestionsAndClose,
  }
  // Cast necessary due to React 15 optional props type
)(ThreeSixtyProductVersion as ComponentClass<Props>);
