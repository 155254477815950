import React from 'react';
import { connect } from 'react-redux';
import {
  faUserCircle,
  faQuestionCircle,
  faStar,
  faRocket,
  faThunderstorm,
} from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadActivity } from '^/actions/items';
import { closeTopModal, openWelcomeModal } from '^/actions/modals';
import { resetLanguageAndCloseTopModal } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import WelcomePage from './WelcomePage';

const SECTIONS = [
  {
    icon: <FontAwesomeIcon icon={faQuestionCircle} />,
    header: <strong>{i18next.t('POTENTIAL part one')}</strong>,
    text: i18next.t('POTENTIAL part one text'),
  },
  {
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    header: <strong>{i18next.t('POTENTIAL part two')}</strong>,
    text: i18next.t('POTENTIAL part two text'),
  },
  {
    icon: <FontAwesomeIcon icon={faStar} />,
    header: <strong>{i18next.t('COMPETENCIES')}</strong>,
    text: i18next.t('COMPETENCIES text'),
  },
  {
    icon: <FontAwesomeIcon icon={faRocket} />,
    header: <strong>{i18next.t('MOTIVATORS')}</strong>,
    text: i18next.t('MOTIVATORS text'),
  },
  {
    icon: <FontAwesomeIcon icon={faThunderstorm} />,
    header: <strong>{i18next.t('DERAILERS')}</strong>,
    text: i18next.t('DERAILERS text'),
  },
];

export class PerspectivesWelcomeModal extends PureComponent {
  render() {
    const {
      productVersionDetails,
      productVersion,
      activity,
      raterFor,
      user,
      uiLanguage,
    } = this.props;
    const welcomeMessageTitle = productVersion.get('welcome_message_title');

    if (!productVersionDetails) {
      return <Loading />;
    }

    return (
      <WelcomePage
        welcomeMessageTitle={welcomeMessageTitle}
        sections={SECTIONS}
        sectionAlertText={i18next.t('Perspectives section alert text')}
        onCancel={() => this.props.resetLanguageAndCloseTopModal(uiLanguage)}
        onContinue={this.props.openWelcomeModal.bind(
          null,
          productVersion,
          activity,
          raterFor,
          productVersionDetails.getIn(['questioncollection_set', 0])
        )}
        productVersion={productVersion}
        user={user}
        respondent={user}
        isSelfRating={!raterFor}
        continueLabel={i18next.t('Next')}
        hideSectionName
        useProductVersionCompletionTime
      />
    );
  }
}

export function mapStateToProps(state, props) {
  const activityDetails = state.items.get('activities');
  return {
    user: state.userProfile,
    productVersionDetails:
      activityDetails &&
      activityDetails
        .get('product_versions', List())
        .find(
          productVersion =>
            productVersion.get('id') === props.productVersion.get('id')
        ),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  loadActivity,
  closeTopModal,
  openWelcomeModal,
  resetLanguageAndCloseTopModal,
})(PerspectivesWelcomeModal);
