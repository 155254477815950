/* eslint-disable no-fallthrough */
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

import {
  setAdditionalWelcomeModalPage,
  setWelcomeModalPage,
  startActivityProductVersionSession,
} from '^/actions/actions';
import {
  openProductVersion,
  startProductVersion,
  resetLanguageAndCloseTopModal,
  setCanResumeAndResetLanguageAndCloseTopModal,
} from '^/actions/actionSequences';
import WelcomePage from '^/components/dashboard/enduser/answer/WelcomePage';
import Loading from '^/components/Loading';
import Well from '^/components/Well';
import {
  isNotInterruptible,
  getAdditionalWelcomeScreens,
} from '^/models/product';
import { interpolateProductVersionDetails } from '^/productVersions';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';
import { getProductVersionNameFromQuestionType } from '^/utils';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import { ACTIVITY_TYPE } from '^/models/activity';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

interface OwnProps {
  page: number;
  setWelcomeModalPage: () => void;
  setAdditionalWelcomeModalPage: () => void;
  startProductVersion: () => void;
  productVersion: Map<string, any>;
  raterFor?: Map<string, any>;
  startSessionResponse: Map<string, any>;
  questionCollection?: Map<string, any>;
}

interface StateProps {
  user: Map<string, any>;
  page: Map<string, any>;
  additionalPage: number;
  activity: Map<string, any>;
  productVersionDetails: Map<string, any>;
  startSessionResponse: Map<string, any>;
  uiLanguage: string;
}

interface DispatchProps {
  setWelcomeModalPage: typeof setWelcomeModalPage;
  setAdditionalWelcomeModalPage: typeof setAdditionalWelcomeModalPage;
  startProductVersion: typeof startProductVersion;
  openProductVersion: typeof openProductVersion;
  startActivityProductVersionSession: typeof startActivityProductVersionSession;
  resetLanguageAndCloseTopModal: typeof resetLanguageAndCloseTopModal;
  setCanResumeAndResetLanguageAndCloseTopModal: typeof setCanResumeAndResetLanguageAndCloseTopModal;
}

type Props = OwnProps & DispatchProps & StateProps;

export class WelcomeModal extends PureComponent<Props> {
  public render() {
    const { activity, page, productVersionDetails } = this.props;
    if (!activity || !productVersionDetails) {
      return <Loading />;
    }
    switch (page) {
      case 0:
      default:
        return this.renderWelcomePage();
      case 1:
        return this.renderAdditionalWelcomePages();
      case 2:
        return this.renderInstructionsPage();
      case 3:
        return this.renderConfirmPage();
      case 4:
        return this.renderBreakPage();
    }
  }
  private jumpToPage(page?: number) {
    const {
      welcome_message_short: welcomeMessageShort,
      welcome_message_long: welcomeMessageLong,
      instructions,
      confirmation_text: confirmationText,
      product,
      question_type: questionType,
    } = this.getProductVersionDetails().toObject();
    const productType = product && product.get('type');
    const isJobProfiler =
      this.props.activity.get('type') === ACTIVITY_TYPE.JOB_PROFILER;
    const additionalWelcomeScreens = getAdditionalWelcomeScreens('');
    const productHasAdditionalWelcomeScreens = isJobProfiler
      ? Boolean(additionalWelcomeScreens[this.props.activity.get('type')])
      : Boolean(
          additionalWelcomeScreens[productType] ||
            additionalWelcomeScreens[questionType]
        );
    switch (page) {
      case 0:
        if (welcomeMessageShort || welcomeMessageLong) {
          return this.props.setWelcomeModalPage(0);
        }

      case 1:
        if (productHasAdditionalWelcomeScreens) {
          return this.props.setWelcomeModalPage(1);
        }

      case 2:
        if (instructions) {
          return this.props.setWelcomeModalPage(2);
        }

      case 3:
        if (confirmationText) {
          return this.props.setWelcomeModalPage(3);
        }

      default:
        this.startProductVersion();
    }
  }

  private process(line?: string) {
    const { productVersion, user, raterFor } = this.props;

    if (!line) {
      return '';
    }

    return interpolateProductVersionDetails(
      line,
      productVersion,
      raterFor || user,
      !raterFor
    );
  }

  private getQuestionCollectionIdx() {
    const { productVersionDetails, questionCollection } = this.props;
    return questionCollection
      ? productVersionDetails
          .get('questioncollection_set')
          .indexOf(questionCollection)
      : 0;
  }

  private canCancel() {
    const { productVersion } = this.props;
    return (
      this.getQuestionCollectionIdx() < 1 ||
      !isNotInterruptible(productVersion.get('product'))
    );
  }

  private onCancel(uiLanguage: string) {
    const { productVersion, productVersionDetails, activity } = this.props;
    if (
      productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PERSPECTIVES
    ) {
      this.props.setCanResumeAndResetLanguageAndCloseTopModal(
        uiLanguage,
        activity.get('id'),
        productVersionDetails.get('id')
      );
    } else {
      this.props.resetLanguageAndCloseTopModal(uiLanguage);
    }
  }

  private renderWelcomePage() {
    const {
      startSessionResponse,
      productVersion,
      user,
      raterFor,
      activity,
      uiLanguage,
    } = this.props;
    const isThreeSixty =
      productVersion.getIn(['product', 'type']) === 'THREE_SIXTY';
    const {
      name,
      welcome_message_short,
      welcome_message_long,
      welcome_message_long_staged,
      welcome_message_title,
      warning_text,
    } = this.getProductVersionDetails().toObject();

    const questionCollectionIdx = this.getQuestionCollectionIdx();
    const isStaged = activity.get('is_staged');

    return (
      <WelcomePage
        name={name}
        welcomeMessageShort={welcome_message_short}
        welcomeMessageLong={
          (isStaged && welcome_message_long_staged) || welcome_message_long
        }
        welcomeMessageTitle={welcome_message_title}
        warningText={warning_text}
        onCancel={
          this.canCancel() ? () => this.onCancel(uiLanguage) : undefined
        }
        onContinue={
          isThreeSixty
            ? this.startSessionAndJumpToPage.bind(this, 1)
            : this.jumpToPage.bind(this, 1)
        }
        productVersion={productVersion}
        user={user}
        respondent={raterFor || user}
        isSelfRating={!raterFor}
        startSessionResponse={startSessionResponse}
        questionCollectionIdx={questionCollectionIdx}
        hideSectionName={activity.get('is_staged')}
        organisationName={activity.getIn(['organisation', 'name'])}
      />
    );
  }

  private startSessionAndJumpToPage(page: number) {
    const { productVersionDetails, activity, raterFor } = this.props;
    this.props.startActivityProductVersionSession(
      activity.get('id'),
      productVersionDetails.get('id'),
      raterFor && raterFor.get('id')
    );
    this.jumpToPage(page);
  }

  private renderInstructionsPage() {
    const {
      startSessionResponse,
      productVersion,
      raterFor,
      questionCollection,
      activity,
    } = this.props;
    const productType = this.props.productVersion.getIn(['product', 'type']);
    const instructions = this.props.productVersionDetails.get('instructions');

    return (
      <AssessmentContainer>
        <AssessmentHeader
          productVersion={productVersion}
          raterFor={raterFor}
          questionCollection={questionCollection}
          showCompletionTime
          organisationName={activity.getIn(['organisation', 'name'])}
        />

        <AssessmentBody
          header={
            <span>
              {i18next.t<string>('Instructions')}
              {productType === 'MAP' && (
                <span>
                  {' '}
                  - {i18next.t<string>('Please read these carefully')}
                </span>
              )}
            </span>
          }
        >
          <Well>
            <ReactMarkdown source={this.process(instructions)} />
          </Well>
        </AssessmentBody>
        <AssessmentFooter>
          <button
            className="btn btn-primary"
            onClick={this.jumpToPage.bind(this, 3)}
            disabled={isPending(startSessionResponse)}
          >
            {i18next.t<string>('Next')}
          </button>
        </AssessmentFooter>
      </AssessmentContainer>
    );
  }

  arrayToStringWithAnd = (arr: Array<string>) => {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    return (
      arr.slice(0, -1).join(', ') +
      ` ${i18next.t<string>('and')} ${arr[arr.length - 1]}`
    );
  };

  private renderBreakPage() {
    const {
      startSessionResponse,
      productVersion,
      user,
      raterFor,
      activity,
      uiLanguage,
    } = this.props;
    const { name } = this.getProductVersionDetails().toObject();

    const questionCollectionIdx = this.getQuestionCollectionIdx();

    const perspectiveSections = this.arrayToStringWithAnd(
      ['1a', '1b', '2', '3', '4'].splice(questionCollectionIdx)
    );

    const welcomeMessageLongText = i18next.t<string>(
      'TRANS save and close message',
      {
        perspectiveSections: perspectiveSections,
      }
    );
    return (
      <WelcomePage
        name={name}
        isBreakPage
        welcomeMessageLong={welcomeMessageLongText}
        welcomeMessageTitle={i18next.t<string>('Instructions')}
        onCancel={
          this.canCancel() ? () => this.onCancel(uiLanguage) : undefined
        }
        onContinue={this.jumpToPage.bind(this, 0)}
        cancelLabel={i18next.t<string>('Save and close')}
        productVersion={productVersion}
        user={user}
        respondent={raterFor || user}
        isSelfRating={!raterFor}
        startSessionResponse={startSessionResponse}
        questionCollectionIdx={questionCollectionIdx}
        hideSectionName
        organisationName={activity.getIn(['organisation', 'name'])}
      />
    );
  }

  private renderAdditionalWelcomePages() {
    const {
      startSessionResponse,
      productVersion,
      productVersionDetails,
      user,
      additionalPage,
      activity,
      uiLanguage,
    } = this.props;

    const isJobProfiler = activity.get('type') === ACTIVITY_TYPE.JOB_PROFILER;
    const jobRole = activity?.get('job_profile')?.get('role_name') || '';
    const welcomeScreens = getAdditionalWelcomeScreens(jobRole)[
      isJobProfiler
        ? this.props.activity.get('type')
        : productVersionDetails.getIn(['product', 'type'])
    ];
    const nextAdditionalPage = additionalPage + 1;

    const onContinue =
      welcomeScreens && nextAdditionalPage < welcomeScreens.length
        ? () => this.props.setAdditionalWelcomeModalPage(nextAdditionalPage)
        : isJobProfiler
        ? () => this.startSessionAndJumpToPage(2)
        : this.jumpToPage.bind(this, 2);
    const welcomeScreenToRender = welcomeScreens[additionalPage];
    const canCancelAdditionalScreen = welcomeScreenToRender.canCancel;

    return (
      <WelcomePage
        {...welcomeScreenToRender}
        onContinue={onContinue}
        onCancel={
          canCancelAdditionalScreen && this.canCancel()
            ? () => this.props.resetLanguageAndCloseTopModal(uiLanguage)
            : undefined
        }
        user={user}
        productVersion={productVersion}
        startSessionResponse={startSessionResponse}
        hideSectionName={activity.get('is_staged')}
      />
    );
  }

  private startProductVersion = () => {
    const { productVersionDetails, activity, raterFor } = this.props;
    const questionCollectionIdx = this.getQuestionCollectionIdx();
    if (questionCollectionIdx < 1) {
      this.props.startProductVersion(productVersionDetails, activity, raterFor);
    } else {
      this.props.openProductVersion(
        productVersionDetails,
        activity,
        raterFor,
        questionCollectionIdx
      );
    }
  };

  private getProductVersionDetails() {
    const { questionCollection, productVersionDetails } = this.props;
    return questionCollection || productVersionDetails;
  }

  private renderConfirmPage() {
    const {
      startSessionResponse,
      productVersion,
      questionCollection,
      raterFor,
      activity,
      uiLanguage,
    } = this.props;
    const {
      question_type,
      intro_text,
      confirmation_text,
    } = this.getProductVersionDetails().toObject();
    const productVersionName = getProductVersionNameFromQuestionType(
      question_type
    );
    const showIntroText =
      ['EAP', 'PSYCAP', 'THREE_SIXTY'].indexOf(question_type) > -1;

    return (
      <AssessmentContainer>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollection={questionCollection}
          raterFor={raterFor}
          showCompletionTime
          organisationName={activity.getIn(['organisation', 'name'])}
        />
        <AssessmentBody>
          {showIntroText && (
            <div>
              {!intro_text && (
                <p className="lead">
                  {productVersionName}
                  {i18next.t<string>(
                    '{{productVersionName}} is designed to help you',
                    {
                      productVersionName,
                    }
                  )}
                </p>
              )}
              <h3>{i18next.t<string>('Completing the survey')}</h3>
              {intro_text ? (
                <ReactMarkdown source={this.process(intro_text)} />
              ) : (
                <div>
                  <p>
                    {i18next.t<string>(
                      'There are several statements about you which you may agree or disagree with.'
                    )}
                  </p>
                  <p>{i18next.t<string>('PARAGRAPH using the rating scale')}</p>
                  <p>
                    {i18next.t<string>(
                      '{{productVersionName}} items have been written in such a way as to be relatively transparent.',
                      { productVersionName }
                    )}
                  </p>
                  <p>{i18next.t<string>('PARAGRAPH personal insight')}</p>
                </div>
              )}
            </div>
          )}
          <Well>
            <ReactMarkdown source={this.process(confirmation_text)} />
          </Well>
        </AssessmentBody>
        <AssessmentFooter>
          {this.canCancel() && (
            <button
              className="btn"
              disabled={isPending(startSessionResponse)}
              onClick={() =>
                this.props.resetLanguageAndCloseTopModal(uiLanguage)
              }
            >
              {i18next.t<string>('Cancel')}
            </button>
          )}
          <button
            className="btn btn-primary"
            disabled={isPending(startSessionResponse)}
            onClick={this.startProductVersion}
          >
            {i18next.t<string>('Next')}
          </button>
        </AssessmentFooter>
      </AssessmentContainer>
    );
  }
}

export function mapStateToProps(
  state: StoreState,
  props: OwnProps
): StateProps {
  const activity = state.items.get('activities');
  return {
    user: state.userProfile,
    page: state.ui.get('welcomeModalPage'),
    additionalPage: state.ui.get('additionalWelcomeModalPage'),
    activity,
    productVersionDetails:
      activity &&
      activity
        .get('product_versions', List())
        .find(
          (productVersion: Map<string, any>) =>
            productVersion.get('id') === props.productVersion.get('id')
        ),
    startSessionResponse: state.responses.get(
      'startActivityProductVersionSession'
    ),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setWelcomeModalPage,
  setAdditionalWelcomeModalPage,
  startProductVersion,
  openProductVersion,
  startActivityProductVersionSession,
  resetLanguageAndCloseTopModal,
  setCanResumeAndResetLanguageAndCloseTopModal,
})(WelcomeModal);
